import './top-section.scoped.scss'

export default function TopSectionComponent() {
    return (
        <div>
            <h1>LUXI JOURNAL</h1>
            <p>This page is a page that contains stories, pictures, activities, information about the Luxi shop which is packaged in a journal that can be enjoyed
                by readers anywhere and anytime to get to know more about what the Luxi shop is.</p>
        </div>
    )
}